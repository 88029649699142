import styled, { css, keyframes } from 'styled-components';
import { brandColors } from '@/themes';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const LoadingContainer = styled.span`
  left: 50%;
  max-height: 60vmin;
  max-width: 60vmin;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 10;

  ${({ mode, size }) => css`
    font-size: ${size * 0.65}px;
    height: ${size}px;
    width: ${size}px;

    ${mode === 'inline' && css`
      left: auto;
      position: static;
      top: auto;
      transform: none;
    `};
  `}
`;

const LoadingInner = styled.div`
  animation: ${rotate} .7s infinite linear;
  border: .25em solid rgba(0, 0, 0, .05);
  border-radius: 100%;
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  ${({ color }) => css`
    border-top-color: ${color};
  `};
`;

const Loader = ({ color, mode, size, style }) => (
  <LoadingContainer className="loader" mode={mode} size={size} style={style}>
    <LoadingInner color={color} />
  </LoadingContainer>
);

Loader.defaultProps = {
  color: brandColors.secondary,
  size: 60
};

export default Loader;
