import styled from 'styled-components';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import groupBy from 'lodash.groupby';

import paths from '@/paths.json';
import Section from '@/components/section';
import { media, colors } from '@/themes';

const Container = styled.div`
  background: ${props => props.theme.colors.background.card};
  text-align: center;
`;

const Header = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
`;

const CurrencyLink = styled.a`
  border-radius: 1em;
  display: inline-block;
  margin: 1em;
  text-align: center;
  width: 10em;
  color: ${({ theme }) => theme.colors.typography.primary};
  text-decoration: none;

  ${media.md`
    margin: 1em .6em;
  `};

  ${media.sm`
    width: calc(50% - 2em);
  `};
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Name = styled.div`
  margin-top: 1em;
  font-weight: 400;
  font-size: 1em;
`;

const Symbol = styled.div`
  font-weight: 400;
  font-size: .8em;
  color: ${colors.typography.secondary};
  margin-top: .5em;
`;

const LogoImage = styled(Image)`
  height: 3em;
  margin: auto;
  width: 3em;
`;

const NetworkAvailability = styled.div`
  font-size: .8em;
  line-height: 1;
  margin-top: .5em;
  color: ${colors.typography.secondary};
`;

const Networks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: .5em;
  margin-top: 1em;
  justify-content: center;
`;

const NetworkLogo = styled(Image)`
  border-radius: 50%;
  overflow: hidden;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .25));
  transform: translate3d(0, 0, 0);
  background: white;
`;

const Currency = ({ currency, currencies = [], urlPrefix, titlePrefix = 'Buy gift cards with' }) => {
  const t = useTranslations('currencies');

  if (currencies.length === 0) return (
    <Link
      href={`/${urlPrefix}-${currency.slug}`}
      passHref={true}
      prefetch={false}
      legacyBehavior
    >
      <CurrencyLink title={`${titlePrefix} ${currency.displayName} - ${currency.displaySymbol}`}>
        <LogoImage unoptimized src={currency.image} width="44" height="44" alt={`${titlePrefix} ${currency.displayName} - ${currency.displaySymbol}`} />
        <Name>{currency.displayName}</Name>
        <Symbol>{currency.displaySymbol}</Symbol>
      </CurrencyLink>
    </Link>
  );

  return (
    <CurrencyLink $as="div">
      <LogoImage unoptimized src={currency.image} width="44" height="44" alt={`${titlePrefix} ${currency.displayName} - ${currency.displaySymbol}`} />
      <Name>{currency.displayName}</Name>
      <NetworkAvailability>{t('networkAvailability')}</NetworkAvailability>
      {currencies.length > 0 &&
        <Networks>
          {currencies.map(currencyByNetwork => (
            <Link href={`/${urlPrefix}-${currencyByNetwork.slug}`} key={currencyByNetwork.network.servicePath} passHref prefetch={false} legacyBehavior>
              <a title={`${titlePrefix} ${currencyByNetwork.displayName} - ${currencyByNetwork.displaySymbol}`}>
                <NetworkLogo unoptimized width="20" height="20" src={`https://assets.bidali.com/networks/${currencyByNetwork.network.servicePath}.png`} alt={`Available in ${currencyByNetwork.network.name} Network`} />
              </a>
            </Link>
          ))}
        </Networks>
      }
    </CurrencyLink>
  );
};

const Currencies = ({ currencies }) => {
  const router = useRouter();
  const t = useTranslations('currencies');
  const stablecoins = groupBy(currencies.filter(c => !!c.fiat), 'displaySymbol');

  return (
    <Container>
      <Section>
        <div>
          <div>
            <Header>{t('stableTitle')}</Header>
            <p>{t('stableSubTitle')}</p>

            <List>
              {Object.keys(stablecoins).map(displaySymbol => {
                const currencies = stablecoins[displaySymbol];
                const currencyName = currencies[0].displayName.replace(/\([^)]*\)/, '');

                if (currencies.length > 1) {
                  return (
                    <Currency
                      currencies={currencies}
                      key={currencies[0].symbol}
                      urlPrefix={paths.currencies.fiat[router.locale]}
                      titlePrefix={t('spend')}
                      currency={{ ...currencies[0], displayName: currencyName }} />
                  );
                }

                return (
                  <>
                    {currencies.map(currency => (
                      <Currency
                        key={currency.symbol}
                        urlPrefix={paths.currencies.fiat[router.locale]}
                        titlePrefix={t('spend')}
                        currency={currency} />
                    ))}
                  </>
                );
              })}
            </List>
            <Header>{t('spendCryptoTitle')}</Header>
            <p>{t('spendCryptoSubTitle')}</p>
            <Header>{t('cryptoTitle')}</Header>
            <List>
              {currencies.filter(c => !c.fiat).map(currency => (
                <Currency
                  key={currency.symbol}
                  urlPrefix={paths.currencies.crypto[router.locale]}
                  titlePrefix={t('buyGiftcards')}
                  currency={currency} />
              ))}
            </List>
          </div>
        </div>
      </Section>
    </Container>
  );
};

export default Currencies;
